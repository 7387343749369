import store from "@/store";
import { TASK_TEMPLATE_FIELDS } from "@connectpath/common";
import { CONTACT_STATES } from "@connectpath/common-frontend";

const { BOOLEAN, NAME, DESCRIPTION, TRUE, FALSE, TEXT } = TASK_TEMPLATE_FIELDS;

let $agent = {},
  $contact;

let formatPhoneNumber = (number) => {
  number = number.trim().replace(/\s/g, "");

  if (number.startsWith("+")) {
    return number;
  } else {
    return "+" + number;
  }
};

const CcpService = {
  async getAgent(agent) {
    try {
      $agent = agent;
      if (!$agent.RoutingProfileARN) {
        $agent.RoutingProfileARN = agent.getConfiguration().routingProfile.routingProfileARN;

        $agent.Region = $agent.RoutingProfileARN.split(":")[3];
        const allQueues = agent.getAllQueueARNs();

        //TODO: estandarize this. Pass needQueueValidation on true when you need it, defaults none property or true to avoid issues with ccp
        if (!("needQueueValidation" in agent) || agent.needQueueValidation) {
          $agent.endpoints = await new Promise((resolve) => {
            agent.getEndpoints(allQueues, {
              success: (endpoints) => {
                resolve(endpoints.endpoints);
              },
              failure: (error) => {
                console.error(error);
              },
            });
          });
        }

        $agent.AWSAccountID = agent.RoutingProfileARN.split(":")[4];

        $agent.InstanceId = (() => {
          let rpARN = $agent.RoutingProfileARN;
          let firstIndex = rpARN.indexOf("instance/") + 9;
          let lastIndex = rpARN.indexOf("/routing");
          let InstanceId = rpARN.slice(firstIndex, lastIndex);
          return InstanceId;
        })();

        $agent.RoutingProfileId = (() => {
          let rpARN = $agent.RoutingProfileARN;
          let lastIndex = rpARN.lastIndexOf("/");
          let RoutingProfileId = rpARN.slice(lastIndex + 1);
          return RoutingProfileId;
        })();
      }

      $agent.state = agent.getState();
      //agentstateUpdate
      let agentState = $agent.state;
      agent.state = getAgentStatusObject(agentState);
      $agent.state = agent.state;

      //agentdataUpdate
      $agent.data = agent._getData();

      let agentdata = $agent.data;
      let agentDataStates = agentdata.snapshot.state;
      agentdata.snapshot.state = getAgentStatusObject(agentDataStates);
      agentdata.snapshot.agentAvailabilityState.state = agentdata.snapshot.state.name;
      agentdata.configuration.agentStates = getAgentStatusList(agentdata.configuration.agentStates);
      agent.data = agentdata;
      $agent.data = agent.data;

      $agent.stateDuration = agent.getStateDuration();
      $agent.permissions = agent.getPermissions();
      $agent.contacts = agent.getContacts();

      //agentConfigurationUpdate
      $agent.configuration = agent.getConfiguration();
      let configuration = $agent.configuration;
      let agentConfigurationState = configuration.agentStates;
      configuration.agentStates = getAgentStatusList(agentConfigurationState);
      agent.configuration = configuration;
      $agent.configuration = agent.configuration;

      $agent.allQueueARNs = agent.getAllQueueARNs();

      //snapshotsUpdate
      $agent.snapshot = agent.toSnapshot();
      let agentSnapshot = $agent.snapshot;
      let agentSnapshotStates = agentSnapshot.agentData.snapshot.state;
      agentSnapshot.agentData.snapshot.state = getAgentStatusObject(agentSnapshotStates);
      agentSnapshot.agentData.snapshot.agentAvailabilityState.state = agentSnapshot.agentData.snapshot.state.name;
      agentSnapshot.agentData.configuration.agentStates = getAgentStatusList(agentSnapshot.agentData.configuration.agentStates);
      agent.snapshot = agentSnapshot;
      $agent.snapshot = agent.snapshot;

      $agent.AWSAgentID = `${$agent.AWSAccountID}-${$agent.configuration.username}`;

      $agent.summary = {
        data: $agent.data,
        Username: $agent.configuration.username,
        AWSAccountID: $agent.AWSAccountID,
        StatusName: $agent.state.name,
        Contacts: $agent.contacts,
        Queues: $agent.allQueueARNs,
        StatusType: $agent.state.type,
        StatusDuration: $agent.stateDuration,
        StatusStartTimestamp: $agent.state.startTimestamp.toISOString(),
        DefaultOutboundQueue: $agent.configuration.routingProfile.defaultOutboundQueue,
        DialableCountries: $agent.configuration.dialableCountries,
        FirstName: $agent.configuration.name,
        LastName: "test",
        Region: $agent.Region,
        InstanceId: $agent.InstanceId,
        InstanceAlias: store.getters.ccpInstanceId,
        RoutingProfileId: {
          Id: $agent.RoutingProfileId,
        },
        Role: $agent.configuration.username.includes("IAM@") ? "instance-admin" : "agent",
      };

      let contactStateMap = {
        connected: "Busy",
        connecting: "PendingBusy",
        ended: "AfterContactWork",
        pending_join: "PendingBusy",
        after_contact_work: "AfterContactWork",
        error: "Error",
        rejected: "Rejected",
        missed: "Missed",
      };

      if ($agent.contacts && $agent.contacts.length > 0) {
        $agent.contacts = $agent.contacts.map((contact) => {
          let state = contact.getState();
          return {
            StatusName: state.type,
            StatusStartTimestamp: new Date(state.timestamp).toISOString(),
          };
        });

        let mostRecentContact = $agent.contacts.sort((a, b) => {
          return new Date(b.StatusStartTimestamp).getTime() - new Date(a.StatusStartTimestamp).getTime();
        })[0];

        $agent.summary.StatusName = contactStateMap[mostRecentContact.StatusName] || "Busy";
        $agent.summary.StatusStartTimestamp = mostRecentContact.StatusStartTimestamp;
        $agent.state.name = $agent.summary.StatusName;
        $agent.state.stateStartTimestamp = $agent.summary.StatusStartTimestamp;
      }

      return $agent;
    } catch (err) {
      console.error("error resolving agent promise: ", err);
      throw err;
    }
    function getAgentStatusObject(agentState) {
      try {
        if (agentState.type === "routable") {
          agentState.name = "Available";
          if (agentState.availabilityState) {
            agentState.availabilityState = "Available";
          }
        }
        if (agentState.type === "offline") {
          agentState.name = "Offline";
          if (agentState.availabilityState) {
            agentState.availabilityState = "Offline";
          }
        }
        return agentState;
      } catch (err) {
        console.error("error", err);
      }
    }
    function getAgentStatusList(agentState) {
      try {
        agentState.forEach((activity) => {
          if (activity.type === "routable") {
            activity.name = "Available";
          }
          if (activity.type === "offline") {
            activity.name = "Offline";
          }
        });
      } catch (err) {
        console.error("error", err);
      }
      return agentState;
    }
  },
  async createTask(agent, params) {
    const { name, description, endpointARN, references, scheduledTime, taskTemplateId = "" } = params;

    const endpoints = await this.getEndpoints(agent);
    const endpoint = endpoints.find((ep) => ep.endpointARN === endpointARN);

    const referencesObj = references.reduce((acc, item) => {
      const { name, value, type } = item;
      acc[name] = { type, value };
      return acc;
    }, {});

    let task = {
      name,
      description,
      endpoint,
      references: referencesObj,
    };

    if (taskTemplateId) {
      task.taskTemplateId = taskTemplateId;
    }
    if (scheduledTime) {
      task.scheduledTime = scheduledTime;
    }

    agent.createTask(task, {
      failure: function (err) {
        console.error(err);
      },
    });
  },
  async getAgentConfiguration() {
    return await $agent.getConfiguration();
  },
  getContact() {
    if ($agent.getContacts().length != 0) {
      $contact = $agent.getContacts()[0];
    } else {
      return new Promise(() => {});
    }
  },

  setState(newState) {
    return new Promise((resolve, reject) => {
      $agent.setState(
        newState,
        {
          success: function () {
            resolve();
          },
          failure: function (error) {
            reject(console.error(error));
          },
        },
        { enqueueNextState: true }
      );
    });
  },

  async getState() {
    return await $agent.getState();
  },

  acceptContact(contact) {
    return new Promise((resolve, reject) => {
      contact.accept({
        success: function () {
          resolve();
        },
        failure: function (error) {
          reject(console.error(error));
        },
      });
    });
  },
  isAgentAvailable() {
    return $agent.getState().name === "Available";
  },
  getOfflineState() {
    return $agent.getAgentStates().find((st) => st.type === window.connect.AgentStatusType.OFFLINE);
  },
  getAvailableState() {
    return $agent.getAgentStates().find((st) => st.type === window.connect.AgentStatusType.ROUTABLE);
  },
  setAgentToOffline() {
    const agent = $agent;
    let offlineState = agent.getAgentStates().filter((st) => st.type === window.connect.AgentStatusType.OFFLINE)[0];

    return this.setState(offlineState)
      .then((data) => {
        window.connect.getLog().info("Agent moved to offline state.");
        return data;
      })
      .catch((reason) => {
        return Promise.reject(reason);
      });
  },

  setAgentToAvailable() {
    const agent = $agent;
    let availableState = agent.getAgentStates().filter((st) => st.type === window.connect.AgentStatusType.ROUTABLE)[0];

    return this.setState(availableState)
      .then((data) => {
        window.connect.getLog().info("Agent moved to available state.");
        return data;
      })
      .catch((reason) => {
        return Promise.reject(reason);
      });
  },

  hangupAgent(contact) {
    return new Promise((resolve, reject) => {
      contact.getAgentConnection().destroy({
        success: function () {
          window.connect.getLog().info("Agent disconnected successfully.");
          resolve();
        },
        failure: function (reason) {
          window.connect.getLog().error("Failed hanging up agent due to " + reason);
          if (typeof reason !== "string") {
            // resolve();
          }
          reject(reason);
        },
      });
    });
  },
  destroyContact(contact) {
    return new Promise((resolve, reject) => {
      contact.clear({
        success: function () {
          resolve();
        },
        failure: function (reason) {
          window.connect.getLog().error("Failed to destroy" + reason);
          reject(reason);
        },
      });
    });
  },

  connectToEndpoint(endpoint, queue) {
    return new Promise((resolve, reject) => {
      let handler = {
        success: function () {
          resolve("calling....");
        },
        failure: function (error) {
          reject(error);
        },
      };

      if (queue) {
        handler.queueARN = queue;
      }
      $agent.connect(endpoint, handler);
    });
  },
  async createContact(destination, queue = null) {
    try {
      if (typeof destination === "object") {
        let endpoint = destination;
        let res = await this.connectToEndpoint(endpoint, queue);
        return Promise.resolve(res);
      } else {
        let parsed = formatPhoneNumber(destination);
        let endpoint = window.connect.Endpoint.byPhoneNumber(parsed);
        let res = await this.connectToEndpoint(endpoint, queue);
        return Promise.resolve(res);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },

  sendDigit(contact, digit) {
    return new Promise((resolve, reject) => {
      contact.getInitialConnection().sendDigits(digit, {
        success: (res) => {
          resolve(res);
        },
        failure: (error) => {
          reject(error);
        },
      });
    });
  },

  muteAgent() {
    return $agent.mute();
  },
  unMuteAgent() {
    return $agent.unmute();
  },

  async holdConnections(contact) {
    const connections = contact.getConnections();
    const myConnection = contact.getAgentConnection();
    let otherActiveConnections = connections.filter((conn) => {
      return conn.isActive() && !conn.isOnHold() && conn.connectionId !== myConnection.connectionId;
    });

    let hold = (connection) => {
      return new Promise((resolve, reject) => {
        connection.hold({
          success: function (res) {
            setTimeout(() => {
              resolve(res);
            }, 1000);
          },
          failure: function (err) {
            reject(err);
          },
        });
      });
    };

    if (otherActiveConnections) {
      for (var i = otherActiveConnections.length - 1; i >= 0; i--) {
        await hold(otherActiveConnections[i]);
      }
    }
  },

  getMonitorCapabilities(contact) {
    const conn = contact.getAgentConnection();
    store.commit("contact/setMonitorCapabilities", conn.getMonitorCapabilities());
  },
  isSilentMonitor(contact) {
    const conn = contact.getAgentConnection();
    store.commit("contact/setIsSilentMonitor", conn.isSilentMonitor());
  },
  isBarge(contact) {
    const conn = contact.getAgentConnection();
    store.commit("contact/setIsBarge", conn.isBarge());
  },
  isUnderSupervision(contact) {
    store.commit("contact/setIsUnderSupervision", contact.isUnderSupervision());
  },
  updateToSilentMonitor(contact) {
    contact.updateMonitorParticipantState("SILENT_MONITOR", {
      success: function () {},
      failure: function (err) {
        console.error("updateToSilentMonitor", err);
      },
    });
  },
  updateToBarge(contact) {
    contact.updateMonitorParticipantState("BARGE", {
      success: function () {
        store.commit("contact/setIsBarge", true);
      },
      failure: function (err) {
        console.error("updateToSilentMonitor", err);
      },
    });
  },

  clearContact(contact) {
    contact.clear({
      failure: function (err) {
        console.error(err);
      },
    });
  },

  async resumeConnections(contact) {
    const connections = contact.getConnections();
    const myConnection = contact.getAgentConnection();

    let heldConnections = connections.filter((conn) => {
      return conn.connectionId !== myConnection.connectionId && conn.isOnHold() && conn.isActive();
    });

    let resume = (connection) => {
      return new Promise((resolve, reject) => {
        connection.resume({
          success: function (res) {
            setTimeout(() => {
              resolve(res);
            }, 2000);
          },
          failure: function (err) {
            reject(err);
          },
        });
      });
    };

    let conference = (contact) => {
      return new Promise((resolve, reject) => {
        contact.conferenceConnections({
          success: function (res) {
            resolve(res);
          },
          failure: function (err) {
            reject(err);
          },
        });
      });
    };

    if (heldConnections.length > 1 && contact.getSingleActiveThirdPartyConnection()) {
      await conference(contact);
    } else {
      for (let connection of heldConnections) {
        await resume(connection);
      }
    }
  },

  async multiPartyHoldConnections(contact) {
    const connections = contact.getConnections();
    const myConnection = contact.getAgentConnection();

    // let otherActiveConnections = connections.filter((conn) => {
    //   return conn.isActive() && !conn.isOnHold() && conn.connectionId !== myConnection.connectionId;
    // });
    let otherActiveConnections = connections.filter((conn) => {
      if (contact?.externalCallStatus != "disconnected") {
        return conn.isActive() && !conn.isOnHold() && conn.getStatus().type != "disconnected";
      } else {
        return conn.isActive() && !conn.isOnHold() && conn.connectionId !== myConnection.connectionId;
      }
    });
    let hold = (connection) => {
      return new Promise((resolve, reject) => {
        connection.hold({
          success: function (res) {
            setTimeout(() => {
              resolve(res);
            }, 1000);
          },
          failure: function (err) {
            reject(err);
          },
        });
      });
    };

    if (otherActiveConnections) {
      for (var i = otherActiveConnections.length - 1; i >= 0; i--) {
        await hold(otherActiveConnections[i]);
      }
    }
  },

  addTransferConnection(endpoint) {
    return new Promise((resolve, reject) => {
      $contact.addConnection(endpoint, {
        success: (res) => {
          resolve(res);
        },
        failure: (err) => {
          reject(err);
        },
      });
    });
  },

  addConnectionByEndpoint(contact, endpoint) {
    return new Promise((resolve, reject) => {
      contact.addConnection(endpoint, {
        success: () => {
          resolve();
        },
        failure: (err) => {
          reject(err);
        },
      });
    });
  },

  conferenceConnections(contact) {
    return new Promise((resolve, reject) => {
      contact.conferenceConnections({
        success: (res) => {
          resolve(res);
        },
        failure: (err) => {
          reject(err);
        },
      });
    });
  },

  /* eslint-disable */
  multiPartyResumeConnections(contact) {
    return new Promise(async (resolve, reject) => {
      const connections = contact.getConnections();
      const myConnection = contact.getAgentConnection();
      let heldConnections;
      if (contact.getInitialConnection().type != "disconnected") {
        heldConnections = connections.filter((conn) => {
          return conn.isOnHold() && conn.isActive() && conn.getStatus().type != "disconnected";
        });
      } else {
        heldConnections = connections.filter((conn) => {
          return conn.connectionId !== myConnection.connectionId && conn.isOnHold() && conn.isActive();
        });
      }
      let resume = (connection) => {
        return new Promise((resolve, reject) => {
          if (connection.isActive() && connection.isOnHold()) {
            new Promise((resolve, reject) => {
              connection.resume({
                success: function (res) {
                  resolve();
                },
                failure: function (err) {
                  reject();
                },
              });
            });
          }
        });
      };
      if (
        heldConnections.length >= 2 &&
        contact.getThirdPartyConnections().length > 0 &&
        contact.getSingleActiveThirdPartyConnection() &&
        !store.getters.externalContactLeft
      ) {
        contact.conferenceConnections({
          success: function (res) {
            resolve(res);
          },
          failure: function (err) {
            reject(err);
          },
        });
      } else {
        for (var i = heldConnections.length - 1; i >= 0; i--) {
          await resume(heldConnections[i]);
        }
      }
    });
  },

  warmTransferConnection(contact, endpoint) {
    this.holdConnection(contact, () => {
      this.addConnection(contact, endpoint, () => {
        this.beginConference(contact, () => {
          this.endConference(contact, () => {});
        });
      });
    });
  },

  holdConnectionAsync(contact) {
    return new Promise((resolve, reject) => {
      let connection = contact.getAgentConnection();
      if (connection) {
        if (!connection.isOnHold()) {
          connection.hold({
            success: function () {
              resolve();
            },
            failure: function (e) {
              reject(new Error("connection hold error: " + e.message));
            },
          });
        } else {
          resolve();
        }
      } else {
        reject(new Error("No active connection found."));
      }
    });
  },

  async multiPartyTransfer(contact, endpoint) {
    try {
      await this.addConnectionByEndpoint(contact, endpoint);
      this.multiPartyResumeConnections(contact);
      this.subscribingRefreshContact(contact);
    } catch (error) {
      console.error(error);
    }
  },

  subscribingRefreshContact(contact) {
    const bus = window.connect.core.getEventBus();
    bus.subscribe(contact.getEventName(window.connect.ContactEvents.REFRESH), function () {
      try {
        if (contact) {
          const outbound = contact.getThirdPartyConnections()[0];
          if (
            outbound?.getStatus().type === CONTACT_STATES.DISCONNECTED ||
            outbound?.getStatus().type === CONTACT_STATES.CONNECTED
          ) {
            this.unsubscribe();
            return;
          }
        }
      } catch (error) {
        console.error("Error in refresh : ", error);
      }
    });
  },

  blindTransferConnection(contact, endpoint, earlyMedia) {
    return new Promise(async (resolve, reject) => {
      const isMultiPartyEnabled = contact.isMultiPartyConferenceEnabled();
      if (isMultiPartyEnabled) {
        try {
          await this.multiPartyTransfer(contact, endpoint);
          resolve();
        } catch (error) {
          reject();
        }
      } else {
        try {
          if (!earlyMedia) {
            await this.addConnectionByEndpoint(contact, endpoint);
            resolve();
          } else {
            await this.addConnectionByEndpoint(contact, endpoint);
            await this.hangupAgent(contact);
            resolve();
          }
        } catch (error) {
          reject(error);
        }
      }
    });
  },

  transferChatConnection(contact, endpoint) {
    return new Promise((resolve, reject) => {
      contact.addConnection(endpoint, {
        success: function (data) {
          resolve(data);
        },
        failure: function (reason) {
          reject(reason);
        },
      });
    });
  },

  /**
   * Holds a connection and provides error handling.
   */
  holdConnection(contact, callback) {
    let connection = contact.getActiveInitialConnection();
    if (connection) {
      if (!connection.isOnHold()) {
        connection.hold({
          success: function () {
            if (callback) {
              return callback();
            }
          },
          failure: function (e) {
            console.error("connection hold error: " + e.message);
          },
        });
      } else {
        callback();
      }
    }
  },

  /**
   * Wrapper method for adding connections with error handling
   * Method executes callback when outbound connection connects.
   */
  addConnection(contact, endpoint, callback) {
    contact.addConnection(endpoint, {
      success: function () {
        if (callback) {
          // Execute callback when outbound call is connected.
          var bus = window.connect.core.getEventBus();
          bus.subscribe(contact.getEventName(window.connect.ContactEvents.REFRESH), function () {
            try {
              var contact = _getContact();
              if (contact) {
                var outbound = contact.getThirdPartyConnections()[0];

                if (outbound?.getStatus().type === CONTACT_STATES.DISCONNECTED) {
                  this.unsubscribe();
                  return;
                }
              }
            } catch (e) {
              console.error("Error in addConnection:OnRefresh. " + e.message);
            }
          });
        }
      },
      failure: function (e) {
        console.error("Couldn't add connection to contact. " + e.message);
      },
    });
  },

  /**
   * Begins conference between Agent, Incoming (customer) and Outgoing (thirdParty)
   */
  beginConference(contact, callback) {
    if (contact) {
      contact.conferenceConnections({
        success: function () {
          if (callback) {
            callback();
          }
        },
        failure: function (e) {
          console.error("Couldn't begin conference for contact. " + e.message);
        },
      });
    } else {
      console.error("Couldn't begin conference, there's no conversation.");
    }
  },

  /**
   * Disconnects agent from conference
   */
  endConference(contact, callback) {
    if (contact) {
      var agentConnection = contact.getAgentConnection();
      if (agentConnection) {
        agentConnection.destroy({
          success: function () {
            if (callback) {
              callback();
            }
          },
          failure: function (e) {
            console.error("Couldn't end agent connection to conference. " + e.message);
          },
        });
      }
    } else {
      console.error("Couldn't end conference, there's no conversation.");
    }
  },

  getEndpoints(agent) {
    return new Promise((resolve) => {
      agent.getEndpoints(agent.getAllQueueARNs(), {
        success: function (endpoints) {
          resolve(endpoints.endpoints);
        },
        failure: (error) => console.error(error),
      });
    });
  },

  async getAgentRoutingProfileName(agent) {
    try {
      return await agent.getConfiguration().routingProfile.name;
    } catch (error) {
      console.error(error);
    }
  },

  async doesRoutingProfileHasQueues(agent) {
    try {
      if (await agent.getConfiguration().routingProfile.queues.length) return true;
      return false;
    } catch (error) {
      console.error(error);
    }
  },
  getTaskTemplate({ agent, taskTemplateId }) {
    const templateParams = {
      id: taskTemplateId,
    };

    agent.getTaskTemplate(templateParams, {
      success: function (data) {
        const taskTemplateParsed = taskTemplateParsedToForm({ taskTemplate: data });
        store.commit("channel/setDefaultTaskTemplateData", taskTemplateParsed);
      },
      failure: function (error) {
        console.error(`CcpService > getTaskTemplate: ${error}`);
        store.commit("channel/setDefaultTaskTemplateData", {});
      },
    });
  },

  updateTaskContact({ agent, references, contactId }) {
    const { name, value, type } = references;
    const transformedObject = {
      [name]: {
        value,
        type,
      },
    };

    const updatedTaskData = {
      contactId,
      references: transformedObject,
    };

    agent.updateContact(updatedTaskData, {
      failure: function (error) {
        console.error(`CcpService > updateTaskContact: ${error}`);
      },
    });
  },
};

function _getContact() {
  return $contact;
}

function _destroyConnection(connection) {
  return connection.destroy({
    success: (res) => console.log("connection destroyed: ", res),
    failure: (err) => console.error("connection not destoyred: ", err.message),
  });
}

const taskTemplateParsedToForm = ({ taskTemplate }) => {
  const allowedTypes = [NAME, DESCRIPTION, TEXT];
  let readOnlyFields = [];

  if (taskTemplate.Constraints.ReadOnlyFields) {
    readOnlyFields = taskTemplate.Constraints.ReadOnlyFields.map((field) => field.Id.Name);
  }

  const formattedObj = {
    Name: taskTemplate.Name,
    Description: taskTemplate.Description,
    Fields: taskTemplate.Fields.filter((field) => allowedTypes.includes(field.Type)).map((field) => {
      const formattedField = {
        Name: field.Id.Name,
        Description: field.Description,
        Prop: createProp({ field }),
        Type: field.Type,
        ReadOnly: readOnlyFields.includes(field.Id.Name),
      };

      if (taskTemplate.Defaults && taskTemplate.Defaults.DefaultFieldValues) {
        const defaultValue = taskTemplate.Defaults.DefaultFieldValues.find((def) => def.Id.Name === field.Id.Name);
        if (defaultValue) {
          formattedField.DefaultValue = defaultValue.DefaultValue;
        }
      }

      const isRequired = taskTemplate.Constraints.RequiredFields.some((reqField) => reqField.Id.Name === field.Id.Name);
      formattedField.Required = isRequired;

      if (isACheckbox({ formattedField })) {
        formattedField.Type = BOOLEAN;
      }

      return formattedField;
    }),
  };

  return formattedObj;
};

const createProp = ({ field }) => {
  if (field.Type === NAME || field.Type === DESCRIPTION) {
    return field.Type.toLowerCase();
  }
  return field.Type === TEXT
    ? `checkbox-${field.Id.Name.replace(/\s+/g, "").toLowerCase()}`
    : field.Id.Name.replace(/\s+/g, "").toLowerCase();
};

const isACheckbox = ({ formattedField }) => {
  return (
    formattedField.Type === TEXT &&
    formattedField.DefaultValue &&
    (formattedField.DefaultValue === TRUE || formattedField.DefaultValue === FALSE)
  );
};

export default CcpService;
